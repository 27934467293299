/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faLinkedin,
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'gatsby';
import classnames from 'classnames';

import Header from './Header';
import '../styles/styles.scss';

interface Props {
  children: React.ReactNode;
  invertNav?: boolean;
}

const Layout = ({ children, invertNav }: Props) => {
  const [email, setEmail] = useState('');

  let pathname = '';

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    pathname = window.location.pathname;
  }

  return (
    <>
      <Header invertNav={invertNav} />

      <main>{children}</main>

      <footer className="footer">
        <hr className="footer__divider" />

        <div className="container">
          <div className="grid grid--align-center">
            <div className="grid__col--xs-12 grid__col--lg-6">
              <form
                action="https://chasechewning.us19.list-manage.com/subscribe/post?u=633aac76ed1ca648e79fed688&amp;id=e0d26aa3d4"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
              >
                <input type="hidden" name="b_633aac76ed1ca648e79fed688_e0d26aa3d4" tabIndex={-1} defaultValue="" />

                <div className="input-group">
                  <input
                    type="email"
                    className="input-group__input"
                    name="EMAIL"
                    placeholder="Join our newsletter!"
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                  />

                  <button
                    type="submit"
                    className="input-group__button button"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>

            <div className="grid__col--xs-12 grid__col--lg-6">
              <ul className="footer-links">
                <li
                  className={classnames(
                    'footer-links__elem',
                    { 'footer-links__elem--active': pathname.startsWith('/podcasts') },
                  )}
                >
                  <Link to="/podcasts">Podcast</Link>
                </li>

                <li
                  className={classnames(
                    'footer-links__elem',
                    { 'footer-links__elem--active': pathname === '/resources' },
                  )}
                >
                  <Link to="/resources">Resources</Link>
                </li>

                <li
                  className={classnames(
                    'footer-links__elem',
                    { 'footer-links__elem--active': pathname === '/sponsors' },
                  )}
                >
                  <Link to="/sponsors">Sponsors</Link>
                </li>

                <li
                  className={classnames(
                    'footer-links__elem',
                    { 'footer-links__elem--active': pathname === '/contact' },
                  )}
                >
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>

              <ul className="social-icons">
                <li className="social-icons__elem">
                  <a
                    href="https://twitter.com/ChaseChewning"
                    rel="noreferrer noopener"
                    target="_blank"
                    title="Follow Chase on Twitter"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>

                <li className="social-icons__elem">
                  <a
                    href="https://www.youtube.com/channel/UCBKF92Pz9PXs70s_AN3-YrA"
                    rel="noreferrer noopener"
                    target="_blank"
                    title="Subscribe to Chase on YouTube"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>

                <li className="social-icons__elem">
                  <a
                    href="https://www.linkedin.com/in/chasechewning"
                    rel="noreferrer noopener"
                    target="_blank"
                    title="Connect with Chase on LinkedIn"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>

                <li className="social-icons__elem">
                  <a
                    href="https://www.facebook.com/chasechewningofficial"
                    rel="noreferrer noopener"
                    target="_blank"
                    title="Follow Chase on Facebook"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </li>

                <li className="social-icons__elem">
                  <a
                    href="https://www.instagram.com/chase_chewning"
                    rel="noreferrer noopener"
                    target="_blank"
                    title="Follow Chase on Instagram"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <p className="footer__copy text-center">
          &copy;
          {new Date().getFullYear()}
          {' '}
          Chase Chewning
          {' | '}
          Developed by
          {' '}
          <a href="https://exobyte.io" target="_blank" rel="noreferrer noopener">Exobyte</a>
        </p>
      </footer>
    </>
  );
};

export default Layout;
