import React, { FunctionComponent, useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import '../styles/header.scss';

interface Props {
  invertNav?: boolean;
}

const Header: FunctionComponent<Props> = ({ invertNav }: Props) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  let pathname = '';

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    pathname = window.location.pathname;
  }

  return (
    <>
      <header className="header">
        <nav className="navbar">
          <Link to="/">
            <h1 style={invertNav ? { color: '#000000' } : undefined}>
              Chase
              <span>Chewning</span>
            </h1>
          </Link>

          <ul className="navbar-list">
            <li
              className={classnames(
                'navbar-list__elem',
                { 'navbar-list__elem--active': pathname === '/' },
              )}
            >
              <Link
                to="/"
                className={classnames(
                  'navbar-list__link',
                  { 'navbar-list__link--inverted': invertNav },
                )}
              >
                Home
              </Link>
            </li>

            <li
              className={classnames(
                'navbar-list__elem',
                { 'navbar-list__elem--active': pathname.startsWith('/podcasts') },
              )}
            >
              <Link
                to="/podcasts"
                className={classnames(
                  'navbar-list__link',
                  { 'navbar-list__link--inverted': invertNav },
                )}
              >
                Podcast
              </Link>
            </li>

            <li
              className={classnames(
                'navbar-list__elem',
                { 'navbar-list__elem--active': pathname === '/resources' },
              )}
            >
              <Link
                to="/resources"
                className={classnames(
                  'navbar-list__link',
                  { 'navbar-list__link--inverted': invertNav },
                )}
              >
                Resources
              </Link>
            </li>

            <li
              className={classnames(
                'navbar-list__elem',
                { 'navbar-list__elem--active': pathname === '/contact' },
              )}
            >
              <Link
                to="/contact"
                className={classnames(
                  'navbar-list__link',
                  { 'navbar-list__link--inverted': invertNav },
                )}
              >
                Contact
              </Link>
            </li>
          </ul>

          <FontAwesomeIcon
            icon={faBars}
            className="mobile-nav-trigger"
            style={{ color: invertNav ? '#000000' : '#ffffff' }}
            onClick={() => setShowMobileNav(true)}
          />
        </nav>
      </header>

      <CSSTransition in={showMobileNav} timeout={300} classNames="slide-down">
        <div className="mobile-nav">
          <div className="mobile-nav__header">
            <h4>Navigation</h4>

            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => setShowMobileNav(false)}
            />
          </div>

          <ul className="mobile-nav-list">
            <li className="mobile-nav-list__elem mobile-nav-list__elem--active text-center">
              <Link to="/" className="mobile-nav-list__link">Home</Link>
            </li>

            <li className="mobile-nav-list__elem text-center">
              <Link to="/podcasts" className="mobile-nav-list__link">Podcast</Link>
            </li>

            <li className="mobile-nav-list__elem text-center">
              <Link to="/resources" className="mobile-nav-list__link">Resources</Link>
            </li>

            <li className="mobile-nav-list__elem text-center">
              <Link to="/contact" className="mobile-nav-list__link">Contact</Link>
            </li>
          </ul>
        </div>
      </CSSTransition>
    </>
  );
};

export default Header;
